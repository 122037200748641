import { DefaultProps } from '../interface';
import HelpTabs from '@pages/help/components/tabs';
import { trackPickUpPaymentType, trackPickUpReadDocument } from './tracking';
import './pick-up.scss';
import { FunctionComponent } from 'react';

const PickUp: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const json = {
    th: {
      เช่ารถที่ไม่ต้องใช้บัตรเครดิต: [
        'หลังทำการจองสำเร็จ จะมีเจ้าหน้าที่จากบริษัทรถเช่าติดต่อกลับ ภายในไม่เกิน 15 นาที เพื่อยืนยันเอกสารและยืนยันการจองอีกครั้ง ท่านสามารถนัดแนะจุดรับรถกับบริษัทรถเช่าได้โดยตรง ไม่ว่าจะเป็นที่สนามบิน ในตัวเมือง หรือที่โรงแรม (หากท่านต้องการรับหรือคืนรถนอกเหนือพื้นที่ที่กำหนด ทางบริษัทรถเช่าจะเรียกเก็บค่าบริการเพิ่มเติม ทั้งนี้ขึ้นอยู่กับเงื่อนไขของแต่ละบริษัท)',
        'โปรดทราบ',
        <>
          เจ้าหน้าที่จะขอเอกสารเพิ่มเติมเพื่อใช้ในการรับรถ ซึ่งประเภทของเอกสารที่ใช้ในการรับรถ
          <br />
          ขึ้นอยู่กับประเภทการจองของคุณ
        </>,
        <a href={`/${prefix}/documents#cash`} key="document" onClick={trackPickUpReadDocument}>
          อ่านเพิ่มเติมเกี่ยวกับเอกสารที่ต้องใช้
        </a>
      ],
      เช่ารถที่ต้องใช้บัตรเครดิต: [
        'คุณสามารถรับรถได้ที่เคาน์เตอร์ที่อยู่ภายในตัวอาคารสนามบิน ซึ่งโดยปกติแล้วเคาน์เตอร์จะอยู่บริเวณทางออกหลังรับกระเป๋าเสร็จแล้ว สำหรับบางสนามบินที่ไม่มีเคาน์เตอร์อยู่ด้านใน จะมีเจ้าหน้าที่ของบริษัทรับรถนั้นๆรอรับท่านที่ทางออกผู้โดยสารขาเข้า',
        'คุณจะไม่สามารถรับรถได้ถ้าไม่มีบัตรเครดิต',
        'บริษัทรถเช่ารายใหญ่ต้องการบัตรเครดิตเพื่อล๊อควงเงิน คุณจะต้องนำบัตรเครดิตไปชำระค่าประกันการเช่า ณ เคาน์เตอร์รับรถ โปรดเช็ควงเงินคงเหลือในบัตรของคุณให้พอกับค่าประกันการเช่า'
      ]
    },
    en: {
      'Renting a Car Without Credit Card:': [
        `Upon successful booking, a representative from the car rental company will reach out to you within 15 minutes to reconfirm documents and booking details. Direct arrangements for car pick-up can be made with the rental company, whether it's at the airport, within the city, or even at your hotel. Should you require car pick-up or return beyond the designated area, please be aware that the rental company may apply an additional service fee, subject to their terms and conditions.`,
        'Important Note:',
        <>
          The representative may request supplementary documents for car pick-up, based on the type of your reservation.
          The specific documents needed will vary depending on your reservation type.
        </>,
        <>
          <span>For further information regarding required documents, please refer to </span>
          <a href={`/${prefix}/documents/#cash`} key="document">
            Documents for Car Rental
          </a>
        </>
      ],
      'Renting a Car with a Credit Card:': [
        `You can pick up your rented vehicle from the counter located within the airport terminal building. Usually, the counter is positioned close to the exit where you retrieve your baggage. In cases where there isn't a counter inside the terminal, the rental company's team will be present in the passenger arrival zone to help you.`,
        'Credit card Required',
        `Interbrand car rental companies require a credit card for a deposit. You'll need to present your credit card at the rental counter to pay for the rental insurance. Please ensure that the available credit limit on your card is sufficient for the insurance fee.`
      ]
    }
  };
  const text = json[prefix];
  return (
    <div className="pick-up">
      <HelpTabs prefix={prefix} onExpand={(paymentType) => trackPickUpPaymentType(paymentType)}>
        {({ LocalTab, InterTab }) => {
          return Object.keys(text).map((title, index) => {
            const values = text[title];
            return index === 0 ? (
              <LocalTab key={title}>
                <div className="mt-4">
                  <h2>{title}</h2>
                  <p className="mb-2">{values[0]}</p>
                  <div className="box--note">
                    <h2>{values[1]}</h2>
                    <p className="mb-0">{values[2]}</p>
                    <ul className="m-0">
                      <li>{values[3]}</li>
                    </ul>
                  </div>
                </div>
              </LocalTab>
            ) : (
              <InterTab key={title}>
                <div className="mt-4">
                  <h2>{title}</h2>
                  <p className="mb-2">{values[0]}</p>
                  <div className="box--attention pick-up-attention">
                    <i className="icon-attention" />
                    <div>
                      <h2>{values[1]}</h2>
                      <p className="mb-0">{values[2]}</p>
                    </div>
                  </div>
                </div>
              </InterTab>
            );
          });
        }}
      </HelpTabs>
    </div>
  );
};

export default PickUp;
