import { sendToDataLayer } from '@utils';

const PICKUP_EVENT_CATEGORY = 'content_pickup_section';
const PICKUP_READ_EVENT_CATEGORY = 'content_rentaltype_section';
export const trackPickUpReadDocument = () => {
  sendToDataLayer(undefined, {
    event_category: PICKUP_READ_EVENT_CATEGORY,
    event_action: 'read_more',
    event_label: `document_sction`
  });
};

export const trackPickUpPaymentType = (paymentType: string) => {
  sendToDataLayer(undefined, {
    event_category: PICKUP_EVENT_CATEGORY,
    event_action: 'payment_type',
    event_label: `${paymentType}`
  });
};
